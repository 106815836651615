<template>
  <div class="reserve-form-wrap">
    <!-- 예약입력  -->
    <div class="reserve-form-wrap--info" v-if="MODE == 1 && !MOBI_YN && !T_BOOL">
      <div class="reserve-form-wrap--top">
        <p class="reserve-form-wrap--title"><strong>휴대전화 번호</strong>를<br>입력해주세요.</p>
        <p class="reserve-form-wrap--text">
          방문예약하신 고객님은<br />
          <strong>예약하신 휴대전화번호로 방문등록</strong>을 하고<br />
          안내에 따라 입장하여 주시기 바랍니다.
        </p>
      </div>
      <div class="reserve-form-wrap--sub">
        <div class="reserve-form-wrap--sub-kakao">
          방문등록하고 <strong>카카오톡</strong>으로 확인하세요
        </div>
      </div>

    </div>
    <!-- 예약입력 대기상태 -->
    <div class="reserve-form-wrap--info type-wait" v-if="MODE == 2 && !MOBI_YN && !T_BOOL">
      <div class="reserve-form-wrap--top">
        <span class="reserve-form-wrap--title">현재대기</span>
        <strong class="reserve-form-wrap--wait">{{ WAIT_NO }} <span class="reserve-form-wrap--wait-unit">팀</span></strong>
        <br>
        <p class="reserve-form-wrap--text">
          상담을 원하시는 고객님께서는<br>
          <strong>휴대전화 번호로 상담신청</strong>을 해주시기 바랍니다.
        </p>
      </div>
      <div class="reserve-form-wrap--sub">
        <div class="reserve-form-wrap--sub-kakao">
          상담석이 지정되면<br>
          <strong>카카오 알림톡</strong>으로 알려드립니다.<br>
        </div>
      </div>
    </div>
    <!-- 공통 전화번호 입력 -->
    <div class="reserve-form-wrap--keypad" v-if="RESULT_MODE == 1 && !MOBI_YN && !T_BOOL">
      <div class="reserve-form-wrap--keypad-in">
        <div class="reserve-form-wrap--keypad-top">
          <input type="text" readonly :value="CUST_PHONE">
        </div>
        <v-checkbox class="reserve-form-wrap--keypad-chk-label" :on-icon="'svg-chk-on'" :off-icon="'svg-chk-off'"
          v-model="IS_AGREE" label="서비스 이용약관과 개인정보 처리방식에 동의합니다."></v-checkbox>
      </div>
      <div class="reserve-form-wrap--keypad-pad">
        <div><button type="button" @click="fnClickNumber(1)">1</button></div>
        <div><button type="button" @click="fnClickNumber(2)">2</button></div>
        <div><button type="button" @click="fnClickNumber(3)">3</button></div>
        <div><button type="button" @click="fnClickNumber(4)">4</button></div>
        <div><button type="button" @click="fnClickNumber(5)">5</button></div>
        <div><button type="button" @click="fnClickNumber(6)">6</button></div>
        <div><button type="button" @click="fnClickNumber(7)">7</button></div>
        <div><button type="button" @click="fnClickNumber(8)">8</button></div>
        <div><button type="button" @click="fnClickNumber(9)">9</button></div>
        <div><button type="button" class="btn-keypad-del" @click="fnDeleteNumber"><span class="hide">del</span></button>
        </div>
        <div><button type="button" @click="fnClickNumber(0)">0</button></div>
        <div><button type="button" class="btn-keypad-in" @click="fnNumberProc">입력</button></div>
      </div>
    </div>

    <div class="reserve-form-wrap--keypad" v-if="RESULT_MODE == 2">
      <div class="reserve-form-wrap--keypad-in" style="text-align:center;line-height: 1.2;">
        {{ CUST_TXT }}<br />
        <span style="font-size:50px;">상담예약이 완료되었습니다.<br /><br /></span>
        <span style="font-size:50px;">대기번호 {{ CNSL_WAIT_NUM }} 번<br /><br /></span>
        <span style="font-size:30px;">카카오 알림톡을 통해 상담순서를<br />
          알려드리니 편안한 관람되시기 바랍니다.</span>
      </div>
      <div class="reserve-form-wrap--keypad-pad" style="height: 30% !important;">
        <div><button type="button" class="btn-keypad-del" @click="fnBackView"><span class="hide">del</span></button></div>
        <div><button type="button" class="btn-keypad-in" @click="fnNumberEnd">확인</button></div>
      </div>
    </div>

    <!-- 공통 전화번호 입력 -->
    <div class="reserve-form-wrap--keypad" v-show="RESULT_MODE == 1 && MOBI_YN && T_BOOL">

      <!-- 예약입력 대기상태 -->
      <div class="reserve-form-wrap--info type-wait" v-show="MODE == 2">
        <div class="reserve-form-wrap--sub">
          <div class="reserve-form-wrap--sub-kakao">
            상담석이 지정되면<br>
            <strong>카카오 알림톡</strong>으로 알려드립니다.<br>
          </div>
        </div>
        <div class="reserve-form-wrap--top">
          <span class="reserve-form-wrap--title">현재대기</span>
          <strong class="reserve-form-wrap--wait">{{ WAIT_NO }} <span
              class="reserve-form-wrap--wait-unit">팀</span></strong>
          <br>
          <p class="reserve-form-wrap--text">
            상담을 원하시는 고객님께서는<br>
            <strong>휴대전화 번호로 상담신청</strong>을 해주시기 바랍니다.
          </p>
        </div>
      </div>

      <div class="reserve-form-wrap--keypad-in">
        <div class="reserve-form-wrap--keypad-top">
          <input type="text" readonly :value="CUST_PHONE">
        </div>
        <v-checkbox class="reserve-form-wrap--keypad-chk-label" :on-icon="'svg-chk-on'" :off-icon="'svg-chk-off'"
          v-model="IS_AGREE" label="서비스 이용약관과 개인정보 처리방식에 동의합니다."></v-checkbox>
      </div>
      <div class="reserve-form-wrap--keypad-pad">
        <div><button type="button" @click="fnClickNumber(1)">1</button></div>
        <div><button type="button" @click="fnClickNumber(2)">2</button></div>
        <div><button type="button" @click="fnClickNumber(3)">3</button></div>
        <div><button type="button" @click="fnClickNumber(4)">4</button></div>
        <div><button type="button" @click="fnClickNumber(5)">5</button></div>
        <div><button type="button" @click="fnClickNumber(6)">6</button></div>
        <div><button type="button" @click="fnClickNumber(7)">7</button></div>
        <div><button type="button" @click="fnClickNumber(8)">8</button></div>
        <div><button type="button" @click="fnClickNumber(9)">9</button></div>
        <div><button type="button" class="btn-keypad-del" @click="fnDeleteNumber"><span class="hide">del</span></button>
        </div>
        <div><button type="button" @click="fnClickNumber(0)">0</button></div>
        <div><button type="button" class="btn-keypad-in" @click="fnNumberProc">입력</button></div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios"
import { stompUtil } from "@/store/stomp-util.js";

export default {
  name: 'MENU_CAP0550_co',
  components: {},
  metaInfo() {
    return {
      title: "상담예약",
    }
  },
  props: {
  },
  data() {
    return {
      CUST_CNSL_ID: '',
      CUST_TXT: '',
      MODE: 2, //예약입력 모드 {1: 기본, 2:대기상태}
      RESULT_MODE: 1,  //결과확인 모드 {1:입력, 2:처리결과}
      KEY_IN: '', //키입력
      CUST_PHONE: '010-',
      BUSS_CODE: this.$route.query.buss_code.replace(/[\"\']/gi, ''),
      CMP_ID: this.$route.query.cmp_id.replace(/[\"\']/gi, ''),
      WAIT_NO: 0, //대기팀
      IS_AGREE: true,
      USER_ID: '',
      CUST_NAME: '',
      CNSL_STAT_CD: 'CNSL_STAT_CD_01', //고객 예약상태 코드
      CNSL_WAIT_NUM: 0, //초기 대기번호
      alertMsg: {
        error: '관리자에게 문의하세요.',
        confirmSave: '저장 하시겠습니까.',
        beforeSaveChk: '현장방문 미등록 고객입니다.\n등록 후 상담예약을 진행해주세요.',
        afterSaveChk: '이미 상담예약 신청을 하셨습니다.',
        callCustChkIngMsg: '상담중인 고객입니다.',
        callCustChkSuccMsg: '상담완료 고객입니다.',
        cmpWrongMsg: '해당 캠페인은 존재하지 않습니다 다른 캠페인을 선택해주세요.',
      },

      ACCESS_TOKEN: "",    //Arthur.Kim_20220924 알림톡토큰
      EXPIRES_IN: "",     //Arthur.Kim_20220924 알림톡토큰유효시간
      VISIT_STR_NUM: 0,
      CMP_INFO_DATAS: [], //캠페인 정보

      FRS_CUS_BOOL: true, //오늘 날짜 기준 첫번째 상담 고객 여부

      MOBI_YN: false,

      T_YN: '',
      T_BOOL: false, //테스트

      TODAY_CNSL_DT: '',
    }
  },

  async mounted() {
    await this.alarmTalkToken();  //알림톡 토큰

    this.init();

    //모바일 여부
    this.MOBI_YN = this.mixin_mobi_yn();
    if (this.MOBI_YN) {
      $('head').append('<meta name="viewport" content="width=device-width, initial-scale=0.6, maximum-scale=0.6, user-scalable=0">');
    }

    if (!this.mixin_isEmpty(this.$route.query.t)) {
      this.T_YN = this.$route.query.t.replace(/[\"\']/gi, '');
      if (this.T_YN == 'Y') {
        this.T_BOOL = true;
      }
    }

    this.ACCESS_TOKEN = this.$store.getters['authStore/GE_ACCESS_TOKEN'];
    this.EXPIRES_IN = this.$store.getters['authStore/GE_EXPIRES_IN'];
  },

  computed: {
    initHeaders() {
      return {
      };
    },
    paramData() {
      return {
      };
    },
  },

  methods: {

    async init() {
      this.connectWs();

      // 초기 호출
      setTimeout(() => this.fnBoardSocketSend(''), 1000);
    },

    alarmTalkToken() {

      this.mixin_alarmTalk_token();

      this.ALARM_TALK_TIMER = setInterval(() => {
        let accessToken = this.access_token;                    // this.alarmTalk_token.access_token;
        if (accessToken === "" || accessToken.length == 0) {
          this.mixin_alarmTalk_token();
        }
      }, 3000);
    },

    fnCmpInfoSet() {
      if (this.CMP_INFO_DATAS.length > 0) {
        for (const iterator of this.CMP_INFO_DATAS) {
          if (!this.mixin_isEmpty(iterator.VISIT_STR_NUM)) {
            this.VISIT_STR_NUM = iterator.VISIT_STR_NUM;
            this.CNSL_WAIT_NUM = this.VISIT_STR_NUM;
          }
        }
      } else {
        this.common_alert(this.alertMsg.cmpWrongMsg, 'chk');
      }
    },

    connectWs() {
      this.USER_ID = this.user_id;
      stompUtil.token = this.$store.state.messages.token; //SOCKET 연결 시 사용자 SOCKET Token 세팅
      stompUtil.userId = this.USER_ID;
      stompUtil.connect(
        () => {
          let url = '/sub/board/' + this.BUSS_CODE;
          stompUtil.stompClient.subscribe(url, message => {
            var messageBody = message.body;
            var messageInfo = JSON.parse(messageBody);

            //오늘기준 캠페인 사업지 고객방문 데이터
            if (messageInfo.cnslrCustEndDatas.length > 0) {
              this.FRS_CUS_BOOL = false;
              const lastCustData = messageInfo.cnslrCustEndDatas[messageInfo.cnslrCustEndDatas.length - 1]; //마지막 고객

              if (!this.mixin_isEmpty(lastCustData) && !this.mixin_isEmpty(lastCustData.CNSL_WAIT_NUM)) {
                this.CNSL_WAIT_NUM = lastCustData.CNSL_WAIT_NUM; //마지막 상담완료(또는 미처리) 고객 대기번호
              }
            } else {
              //캠페인 정보
              this.CMP_INFO_DATAS = messageInfo.cmpInfoDatas;
              if (this.VISIT_STR_NUM == 0) {
                this.fnCmpInfoSet();
              }
            }

            //대기고객 1명이상
            if (messageInfo.datas.length > 0) {
              const data = messageInfo.datas[0]; //첫번째 고객

              if (messageInfo.ACT === 'CUST') {
                this.WAIT_NO = data.WAITING_STAFF_COUNT;
              } else {
                this.WAIT_NO = data.WAITING_STAFF_COUNT;
              }
            } else {
              this.WAIT_NO = 0;
            }
          });
        },
        (error) => {
          if (error != null) {
            console.log(error);
            stompUtil.setConnected(false);
          }
        }
      );
    },

    fnClickNumber(clickNumber) {
      if (this.mixin_isEmpty(this.CUST_PHONE)) {
        this.CUST_PHONE = clickNumber;
      } else {
        if (this.CUST_PHONE.length < 13) {
          this.CUST_PHONE = this.fnNumberFomat(this.CUST_PHONE + clickNumber);
        }
      }
    },

    fnDeleteNumber() {
      if (this.CUST_PHONE != undefined && this.CUST_PHONE != '') {
        var makePhoneNumber = this.CUST_PHONE.replaceAll('-', '');
        makePhoneNumber = makePhoneNumber.substring(0, makePhoneNumber.length - 1);
        this.CUST_PHONE = this.fnNumberFomat(makePhoneNumber);
      }
    },

    fnNumberProc() {
      if (!this.IS_AGREE) {
        this.common_alert("이용약관 동의후에 상담예약등록해 주세요.", "chk");
        return;
      }

      if (this.CUST_PHONE.length < 13) {
        this.common_alert("휴대전화번호를 정확히 입력해 주세요.", 'chk');
      } else {
        if (!this.custOnSiteChk()) {
          return;
        }
      }
    },

    // 미사전예약 및 사전예약 현장방문 등록정보 체크(오늘 일자 등록여부)
    async custOnSiteChk() {

      let requestData = {
        headers: {
          SERVICE: 'hlw.campaign.manage.counse',
          METHOD: 'data',
          TYPE: 'BIZ_SERVICE',
          URL: "/api/hlw/campaign/manage/counse/data",
        },
        sendData: {}
      };

      requestData.sendData["CMP_ID"] = this.CMP_ID;
      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
      requestData.sendData["CUST_PHONE"] = this.CUST_PHONE;

      //api 호출 부분
      let responseGridList = await this.common_postCall(requestData);
      let resHeader = responseGridList.HEADER;
      let resData = responseGridList.DATA;

      // 데이터 조회 오류 시 alert
      if (resHeader.ERROR_FLAG) {
        this.RESULT_MODE = 1;
        this.common_alert(this.alertMsg.error, "error");
        this.fnNumberEnd();
        return false;
      }

      if (this.mixin_isEmpty(resData)) {
        this.RESULT_MODE = 1;
        this.common_alert(this.alertMsg.beforeSaveChk, 'chk');
        this.fnNumberEnd();
        return false;
      } else {
        resData = resData[0];

        if (resData.CNSL_STAT_CD != "" && resData.CNSL_STAT_CD != 'CNSL_STAT_CD_03') { //미처리가 아닐 경우
          this.RESULT_MODE = 1;

          let msg = this.alertMsg.afterSaveChk;

          if (resData.CNSL_STAT_CD == 'CNSL_STAT_CD_02') { //상담중 경우
            msg = this.alertMsg.callCustChkIngMsg;
          } else if (resData.CNSL_STAT_CD == 'CNSL_STAT_CD_04') { //상담완료 경우
            msg = this.alertMsg.callCustChkSuccMsg;
          }

          this.common_alert(msg, 'chk');
          this.fnNumberEnd();
          return false;

        } else {
          if (resData.CUST_NAME == "") {
            this.CUST_TXT = this.CUST_PHONE.substring(0, this.CUST_PHONE.length - 4) + '****';
          } else {
            this.CUST_TXT = resData.CUST_NAME.substring(0, resData.CUST_NAME.length - 1) + '*';
          }

          this.RESULT_MODE = 2;
          this.CUST_CNSL_ID = resData.CUST_CNSL_ID; //고객상담 등록 데이터 key

          //오늘 상담예약 신청일시
          let cnslDt = resData.REG_DT;
          if (this.mixin_isEmpty(cnslDt)) {
            cnslDt = resData.UPD_DT;
          }

          if (!this.mixin_isEmpty(cnslDt)) {
            this.TODAY_CNSL_DT = cnslDt.substr(0, 16);
          }

          //상담 예약 고객정보 DB 등록
          this.fnRegist();
        }
      }
    },

    // 입력된 데이터 저장
    fnRegist() {
      this.fnRegiCall();
    },

    async fnRegiCall() {
      let requestData = {
        headers: {
          SERVICE: 'hlw.campaign.manage.counse',
          METHOD: 'regist',
          TYPE: 'BIZ_SERVICE',
          URL: "/api/hlw/campaign/manage/counse/regist",
        },
        sendData: {}
      };

      requestData.sendData["CMP_ID"] = this.CMP_ID;
      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
      requestData.sendData["CUST_PHONE"] = this.CUST_PHONE;
      requestData.sendData["CNSL_STAT_CD"] = this.CNSL_STAT_CD;
      requestData.sendData["CNSL_WAIT_NUM"] = this.CNSL_WAIT_NUM;
      requestData.sendData["USER_ID"] = this.USER_ID;
      requestData.sendData["CUST_CNSL_ID"] = this.CUST_CNSL_ID;

      //api 호출 부분
      let responseGridList = await this.common_postCall(requestData);
      let resHeader = responseGridList.HEADER;
      let resData = responseGridList.DATA;

      // 데이터 조회 오류 시 alert
      if (resHeader.ERROR_FLAG) {
        this.common_alert(this.alertMsg.error, "error");
        return false;;
      } else {
        if (!this.mixin_isEmpty(resData)) {
          resData.forEach((ele, i) => {
            if (!this.mixin_isEmpty(ele.CNSL_WAIT_NUM)) {
              this.CNSL_WAIT_NUM = ele.CNSL_WAIT_NUM;
            }
          });

          this.fnBoardSocketSend('CUST');

          if (!this.mixin_isEmpty(this.CNSL_WAIT_NUM)) {
            //알림톡 발송
            this.sendMessage();
          }
        }
      }
    },

    fnBoardSocketSend(param) {
      if (stompUtil.isConnected()) {
        var sendData = {
          ACT: param,
          CMP_ID: this.CMP_ID,
          BUSS_CODE: this.BUSS_CODE,
          WAIT_NO: this.WAIT_NO,
          CNSL_STAT_CD: this.CNSL_STAT_CD,
        };
        var sendDataStr = JSON.stringify(sendData);
        stompUtil.stompClient.send("/wait/buss/counsel/board", sendDataStr);
      }
    },

    async sendMessage() {

      // this.mixin_alarmTalk_token();
      this.ACCESS_TOKEN = this.$store.getters['authStore/GE_ACCESS_TOKEN'];
      this.EXPIRES_IN = this.$store.getters['authStore/GE_EXPIRES_IN'];

      let requestData = {
        headers: {},
        sendData: {},
      };

      //header 세팅
      requestData.headers["URL"] = "/api/hlw/campaign/alarmTalk-msgformat/manage/list";
      requestData.headers["SERVICE"] = "hlw.campaign.alarmTalk-msgformat.manage";
      requestData.headers["METHOD"] = "list";
      requestData.headers["ASYNC"] = false;

      //sendData 초기화
      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;              //사업지 아이디
      requestData.sendData["CMP_ID"] = this.CMP_ID;                   //캠페인 아이디
      requestData.sendData["MSG_TMPL_TYPE"] = "APP_CONSULT";          //메세지 속성 코드(신청완료)    

      let response = await this.common_postCall(requestData);

      if (!response.HEADER.ERROR_FLAG) {

        let data = response.DATA;
        let data_btn = response.DATA_BTN;

        if (data.length > 0) {

          // 현재 대기자수
          let talkWaitNo = this.WAIT_NO;

          let msgFormat = data[0].MSG_CONT;  //메세지내용
          msgFormat = msgFormat.replace(/#{사업지명}/g, data[0].BUSS_NAME)
            .replace(/#{고객명}/g, this.CUST_TXT)
            .replace(/#{상담신청일시}/g, this.TODAY_CNSL_DT)
            .replace(/#{대기번호1}/g, this.CNSL_WAIT_NUM)
            .replace(/#{대기번호2}/g, this.CNSL_WAIT_NUM)
            .replace(/#{대기자수}/g, talkWaitNo)

          // let msgFormatDecode = this.decode(msgFormat);

          let setButtons = [];
          if (data_btn.length > 0) {

            for (let i = 0; i < data_btn.length; i++) {
              setButtons.push({
                name: data_btn[i].BTN_NAME,
                type: data_btn[i].BTN_LINK_TYPE,
                url_mobile: data_btn[i].BTN_LINK_URL
              });
            }
          }

          const sendButton = { "button": setButtons };

          let requestAlarmTalkData = {
            headers: {},
            sendData: {},
          };

          let buttonToString = `'${JSON.stringify(sendButton)}'`;

          requestAlarmTalkData.headers["URL"] = "/api/hlw/campaign/alarmTalk-postmsg/manage/post";
          requestAlarmTalkData.headers["SERVICE"] = "hlw.campaign.alarmTalk-postmsg.manage";
          requestAlarmTalkData.headers["METHOD"] = "post";
          requestAlarmTalkData.headers["ASYNC"] = false;

          requestAlarmTalkData.sendData["CMP_ID"] = this.CMP_ID;
          requestAlarmTalkData.sendData["TRAN_SENDER_KEY"] = data[0].SNDR_KEY;
          requestAlarmTalkData.sendData["TRAN_TMPL_CD"] = data[0].MSG_TMPL_CODE;
          requestAlarmTalkData.sendData["TRAN_PHONE"] = this.CUST_PHONE.replaceAll("-", "");;
          requestAlarmTalkData.sendData["TRAN_CALLBACK"] = data[0].SNDR_PHONE.replaceAll("-", "");
          requestAlarmTalkData.sendData["TRAN_MSG"] = msgFormat;
          requestAlarmTalkData.sendData["TRAN_REPLACE_MSG"] = msgFormat;
          if(sendButton["button"].length != 0) requestAlarmTalkData.sendData["TRAN_BUTTON"] = this.encode(buttonToString);

          let alarmTalkResponse = await this.common_postCall(requestAlarmTalkData);
          if (alarmTalkResponse.HEADER.ERROR_FLAG) {
            this.common_alert("관리자에게 문의해주세요.", "error");
          }
        }
      }
    },

    fnNumberFomat(phoneNumber) {
      var makePhoneNumber = "";
      if (phoneNumber != undefined && phoneNumber != '') {
        makePhoneNumber = phoneNumber.replaceAll('-', '');
        if (makePhoneNumber.length > 9) {
          makePhoneNumber = this.mixin_setPhoneNo(makePhoneNumber);
        } else {
          if (makePhoneNumber.length === 3) {
            makePhoneNumber = makePhoneNumber + '-';
          } else if (makePhoneNumber.length === 7) {
            makePhoneNumber = makePhoneNumber.substring(0, 3) + '-' + makePhoneNumber.substring(3) + '-';
          } else if (makePhoneNumber.length > 7) {
            makePhoneNumber = makePhoneNumber.substring(0, 3) + '-' + makePhoneNumber.substring(3, 7) + '-' + makePhoneNumber.substring(7);
          } else if (makePhoneNumber.length > 3) {
            makePhoneNumber = makePhoneNumber.substring(0, 3) + '-' + makePhoneNumber.substring(3);
          }
        }
      }
      return makePhoneNumber;
    },

    fnBackView() {
      this.RESULT_MODE = 1;
    },

    fnNumberEnd() {
      this.RESULT_MODE = 1;
      this.IS_AGREE = true;
      this.CUST_PHONE = '010-';
    },

    decode(strText) {

      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }
      //순서바뀌면 안나옴
      strText = strText.replaceAll("&lt;", "<");
      strText = strText.replaceAll("&gt;", ">");
      strText = strText.replaceAll("&amp;", "&");
      strText = strText.replaceAll("&quot;", '"');
      strText = strText.replaceAll("&apos;", "'");
      strText = strText.replaceAll("<br>", "\r");
      strText = strText.replaceAll("<p>", "\n");
      strText = strText.replaceAll("&#91;", "[");
      strText = strText.replaceAll("&#93;", "]");
      strText = strText.replaceAll("&#40;", "(");
      strText = strText.replaceAll("&#41;", ")");

      return strText;
    },
    encode(strText) {
      // 값 존재 유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }

      strText = strText.toString();

      // 문자열 길이가 4 이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }

      // 문자열을 HTML 엔티티로 인코딩
      strText = strText.replaceAll("&", "&amp;");
      strText = strText.replaceAll("<", "&lt;");
      strText = strText.replaceAll(">", "&gt;");
      strText = strText.replaceAll('"', "&quot;");
      strText = strText.replaceAll("'", "&apos;");
      strText = strText.replaceAll("\r", "<br>");
      strText = strText.replaceAll("\n", "<p>");
      strText = strText.replaceAll("\[", "&#91;");
      strText = strText.replaceAll("\]", "&#93;");
      strText = strText.replaceAll("\(", "&#40;");
      strText = strText.replaceAll("\)", "&#41;");

      return strText;
    }
  },

}
</script>

<style>
.reserve-form-wrap {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif' !important;
}
</style>