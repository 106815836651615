<template>
  <div>
    <CAP0550_co :key="componentKey" @reload="forceReload"/>
  </div>
</template>

<script>
import CAP0550_co from './CAP0550_co.vue'

export default {
  name: "MENU_CAP0560",
  components: {
    CAP0550_co
  },
  data() {
    return {
      componentKey: 0
    };
  },
  methods: {
    forceReload() {
      this.componentKey += 1;
    }
  }
}
</script>
